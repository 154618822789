<template>
  <div class="transmit_sub_container">
    <div class="transmittal_slip_filter">
      <label>Date</label>
      <flat-pickr v-model="filter.date" :config="configDate" placeholder="Select Date" name="birthdate"></flat-pickr>
      <label>Receiver Type</label>
      <select v-model="filter.type" @change="fetchDropdownData">
        <option value="hub">Hub</option>
        <option value="branch">Branch</option>
        <option value="name">Name</option>
        <option value="address">Address</option>
      </select>
      <select v-model="filter.name" v-if="filter.type == 'hub'">
        <option value="">Select hub</option>
        <option v-for="(hub,i) in hubs" :key="'hubs_'+i" :value="hub.name">
          {{hub.name}}
        </option>
      </select>
      <select v-model="filter.name" v-else-if="filter.type == 'branch'">
        <option value="">Select branch</option>
        <option v-for="(branch,i) in branches" :key="'branches_'+i" :value="branch.name">
          {{branch.name}}
        </option>
      </select>
      <div v-else-if="filter.type == 'name'">
      <input type="text" v-model="filter.name" class="dynamic_data_trigger" @focus="show_name = true" @blur="hideDropdown('show_name')"/>
      <div class="dynamic_data_wrapper" v-if="show_name">
          <span 
            v-for="(name,i) in filterName" 
            :key="'receiver_address'+i"
            @click="filter.name = name"
            >
            {{name}}
          </span>
        </div>
      </div>
      <div v-else-if="filter.type == 'address'">
      <input type="text" v-model="filter.name" class="dynamic_data_trigger" @focus="show_address = true" @blur="hideDropdown('show_address')"/>
      <div class="dynamic_data_wrapper" v-if="show_address">
          <span 
            v-for="(name,i) in filterAddress" 
            :key="'receiver_address'+i"
            @click="filter.name = name"
            >
            {{name}}
          </span>
        </div>
      </div>
      <input type="button" value="Search" @click="fetchCouriers"/>
    </div>
    <loader v-if="loading"/>
    <div v-if="show_data && !loading && list.length > 0">
    <section>
      <div class="form_section_transmit">
        <table class="transmit_table1">
          <tr>
            <td>USER BRANCH</td>
            <td>SUMMARY TRANSMIT SLIP</td>
            <td>
              <img src="../../assets/temp_logo1_new.png" />
            </td>
          </tr>
        </table>
      </div>
    </section>
    <section>
      <div class="form_section_transmit">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table class="transmit_table2">
          <tr>
            <td>
              <div class="service_name">
                <div class="label_and_element_wrapper">
                <ValidationProvider name="carrier" rules="required" v-slot="{ errors }" slim>
                  <select v-model="filter.provider_id" :class="errors[0] ? 'warning-border':''" @change="fetchServices">
                    <option value="">Select Carrier</option>
                    <option v-for="(provider,i) in providers" :key="'providers'+i" :value="provider.provider_id">
                      {{provider.name}}
                    </option>
                  </select>
                  </ValidationProvider>
                </div>
                </div>
              <div class="courier_name">
                <div class="label_and_element_wrapper">
                <ValidationProvider name="service" rules="required" v-slot="{ errors }" slim>
                  <select v-model="filter.service_id" :class="errors[0] ? 'warning-border':''" :disabled="services.length == 0">
                    <option value="" disabled>Select Service</option>
                   <option   v-for="(service,i) in services" :key="'services'+i" :value="service.id">
                      {{service.name}}
                    </option>
                  </select>
                  </ValidationProvider>
                </div>
               </div>

                <div class="courier_name">
                <div class="label_and_element_wrapper">
                <label style="line-height: 0;height: 20px;padding-left: 0;">Total Weight</label>
                <ValidationProvider name="service" rules="required" v-slot="{ errors }" slim>
                    <input type="text" placeholder="Enter Weight" v-model="filter.weight"  :class="errors[0] ? 'warning-border':''" :disabled="isDisabled"/>
                  </ValidationProvider>
                </div>
               </div>

                <div class="courier_name" style="margin-bottom: 10px;">
                <div class="label_and_element_wrapper">
                <label style="line-height: 0;height: 20px;margin-top: 15px;padding-left: 0;">No of Pieces</label>
                <ValidationProvider name="service" rules="required" v-slot="{ errors }" slim>
                    <input type="number" placeholder="Enter No of Pieces" v-model="filter.NoOfPieces"  :class="errors[0] ? 'warning-border':''" min="1" max="1000" autocomplete="new-password">
                  </ValidationProvider>

                </div>
               </div>


            </td>
            <td>
              <div>
                {{filter.name}}
                <br />
                <a class="edit" @click="showpopSelectDestination = true">SELECT DESTINATION</a>
              </div>
            </td>
            <td>
              <div class="dispatch_date">
                Dispatch date:
                <span>{{moment(filter.date).format("MM-DD-YYYY")}}</span>
              </div>

              <div class="bar_text"></div>
            </td>
          </tr>
        </table>
        </ValidationObserver>
      </div>
    </section>
    <section>
      <div class="form_section_transmit">
        <table class="transmit_table3">
          <tr>
            <td>REF</td>
            <td>ADDRESSEE</td>
            <td>ITEM</td>
            <td>ITEM DESCRIPTION</td>
            <td>SENDER</td>
            <td>ACTION</td>
          </tr>
          <tr v-for="(data,i) in list" :key="'courier'+i">
            <td>
              <barcode :value="data.booking_prefix + data.booking_id" tag="img"/>
            </td>
            <td>
              <p v-if="data.delivery_name">{{data.delivery_name}}</p>
              <p v-if="data.delivery_address_1">{{data.delivery_address_1}}</p>
              <p v-if="data.delivery_address_2">{{data.delivery_address_2}}</p>
              <p v-if="data.delivery_city">{{data.delivery_city}}</p>
              <p v-if="data.delivery_state">{{data.delivery_state}}</p>
              <p v-if="data.delivery_country">{{data.delivery_country}}</p>
            </td>
            <td>{{data.item_qty}}</td>
            <td>{{data.goods_description || 'NA'}}</td>
            <td>{{data.pickup_name}}</td>
            <td>
              <input type="checkbox" class="dispatch_chk" v-model="data.is_checked"/>
            </td>
          </tr>
        </table>
      </div>
    </section>
  
    <section class="centered">
      <a class="link_bt bt_cancel" @click="$router.push('/pages/dashboard')">Cancel</a>
      <a class="link_bt bt_save" @click="createHubSlip" :disabled="submit_btn.disabled">{{submit_btn.value}}</a>
    </section>
  </div>
    <h3 v-if="show_data && !loading && list.length == 0" class="text-center p-3">No records found</h3>
    <div class="black-overlay" v-if="showpopSelectDestination">
      <div class="box-modal smallPops">
        <div class="body">
          <popSelectDestination
            v-if="showpopSelectDestination"
            @closeModal="showpopSelectDestination = false"
            :dropdownValues="filter"
            @appliedFilter="filterCouriers"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import popSelectDestination from "./popups/popSelectDestination.vue";
export default {
  name: "x",
  components: {
    flatPickr,
    popSelectDestination
  },
  computed:{
    filterName(){
      return this.name_list.filter(element => {
        if(element.toLowerCase().includes(this.filter.name.toLowerCase())){
          return element;
        }
      }).slice(0,10);
    },
    filterAddress(){
      return this.address_list.filter(element => {
        if(element.toLowerCase().includes(this.filter.name.toLowerCase())){
          return element;
        }
      }).slice(0,10);
    }
  },
  data() {
    return {
      date: null,
      enableTime: true,
      filter:{
        type:"hub",
        date:"",
        name:"",
        service_id:"",
        provider_id:"",
        bookings:[],
        weight:"1",
        NoOfPieces:"1"
      },
      list:[],
      services:[],
      providers:[],
      hubs:[],
      branches:[],
      name_list:[],
      address_list:[],
      configDate: {
        altInput: true,
        noCalendar: false,
         format: "d-m-Y",
          altFormat: "d-m-Y",
      },
      show_name:false,
      show_address:false,
      isDisabled: false,
      showpopSelectDestination: false,
      loading:false,
      show_data:false,
      submit_btn:{
        disabled:false,
        value:"GENERATE SLIP"
      }
    };
  },
  created(){
    this.$emit("breadCrumbData",["Hub Dispatch","Generate Slip Search"]);
    this.filter.date = this.moment(new Date(Date.now())).format("YYYY-MM-DD");
    this.fetchProviders();
    this.fetchDropdownData();
  },
  methods:{
    fetchProviders(){
      this.axios.get("api/provider/domestic")
                .then(response => {
                  this.providers = response.data.providers;
                })
                .catch(error => {
                  console.log(error);
                });
    },
     fetchServices(){
      this.services = [];
      this.axios.get("api/provider/carrier/"+this.filter.provider_id)
                .then(response => {
                  console.log( response.data.services);
                  this.services = response.data.provider.servicenames;
                if(this.services.length > 0){
                
                this.filter.service_id = this.services[0].id;
            }
           

                })
                .catch(error => {
                  console.log(error);
                });    
    },
    fetchNames(){
      this.axios.post("api/booking/name/list")
        .then(response => {
          this.name_list = response.data.name;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchAddresses(){
      this.axios.post("api/booking/address/list")
        .then(response => {
          this.address_list = response.data.address;
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchDropdownData(resetname = true){
      this.hubs = [];
      this.branches = [];
      this.name_list = [];
      if(resetname){
        this.filter.name = "";
      }
      if(this.filter.type == 'hub'){
        this.fetchHubs()
      }else if(this.filter.type == 'branch'){
        this.fetchBranches();
      }else if(this.filter.type == 'name'){
        this.fetchNames();
      }else if(this.filter.type == 'address'){
        this.fetchAddresses();
      }
    },
    hideDropdown(type){
      setTimeout(() => {
        this[type] = false;
      },200);
    },
    fetchHubs(){
      this.axios.get("api/division/exceptCurrent")
                .then(response => {
                  this.hubs = response.data.divisions;
                  console.log(this.hubs);
                  console.log("this.hubs");

                })
                .catch(error => {
                  console.log(error);
                });
    },
    fetchBranches(){
      this.axios.get("api/branch/view")
                .then(response => {
                  this.branches = response.data.branches;
                })
                .catch(error => {
                  console.log(error);
                });
    },
    fetchCouriers(){
      this.loading = true;
      this.show_data = false;
      this.list = [];
      console.log(this.filter);
      console.log('this.filter');
      this.axios.post("api/hub/slip/view",this.filter)
                .then(response => {
                  this.list = response.data.list.map(element => {
                    element.is_checked = true;
                    return element;
                  });
                  this.loading = false;
                  this.show_data = true;
                })
                .catch(error => {
                  console.log(error);
                  this.loading = false;
                });
    },
    createHubSlip(){
      this.submit_btn.disabled = true;
      this.submit_btn.value = "GENERATING SLIP...";
      this.fetchCheckedBookings();
      this.$refs.observer.validate();
      this.axios.post("api/hub/slip",this.filter)
                .then(response => {
                  this.toast.success(response.data.msg);
                  this.submit_btn.disabled = false;
                  this.submit_btn.value = "GENERATE SLIP";
                  this.$router.push("/pages/ViewTransmittalSlip");
                })
                .catch(error => {
                  console.log(error);
                  this.submit_btn.disabled = false;
                  this.submit_btn.value = "GENERATE SLIP";
                });
    },
    filterCouriers(data){
      this.filter.type = data.type;
      this.filter.name = data.name;
      this.fetchDropdownData(false);
      this.fetchCouriers();
    },
    fetchCheckedBookings(){
      this.filter.bookings = [];
      this.list.forEach(element => {
        if(element.is_checked){
          this.filter.bookings.push(element.booking_id);
        }
      });
    }
  }
};
</script>
<style lang="scss">
input[type="checkbox"].dispatch_chk {
  width: 20px;
  height: 20px;
  border: 1px solid #333;
}
.text-center{
  text-align:center;
}
.p-3{
  padding:1.5rem;
}
.dynamic_data_wrapper{
  background:rgb(230, 230, 230);
  display:flex;
  flex-direction: column;
  width:200px;
}
.dynamic_data_wrapper span{
  border-bottom:1px solid rgb(177, 177, 177);
  padding:0.5rem;
  font-size:0.9rem;
  color:rgb(51, 51, 51);
}
.dynamic_data_wrapper span:hover{
  background:black;
  color:white;
  border-bottom:1px solid white;
}
</style>